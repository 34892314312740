








import { Component, Prop, Vue } from 'vue-property-decorator'
import DecorateWidget from '@/components/decorate/decorate-widget.vue'
import DecoratePhone from '@/components/decorate/decorate-phone.vue'
import DecorateAttr from '@/components/decorate/decorate-attr.vue'

@Component({
    components: {
        DecorateWidget,
        DecoratePhone,
        DecorateAttr
    }
})
export default class DecorateIndex extends Vue {
    /** S data **/
    id!: any
    API_TYPE:any = '1'
    component = [
        {
            name: 'search'
        },
        {
            name: 'title'
        },
        {
            name: 'banner'
        },
        {
            name: 'navigation'
        },
        {
            name: 'goodsgroup'
        },
        {
            name: 'tabs'
        },
        {
            name: 'notice'
        },
        {
            name: 'blank'
        },
        {
            name: 'separate'
        },
        {
            name: 'rubik'
        },
        {
            name: 'coupon'
        },
        {
            name: 'seckill'
        },
        {
            name: 'spellgroup'
        },
        {
            name: 'video'
        },
        {
            name: 'graphic'
        },
        {
            name: 'mnplive'
        },
        {
            name: 'ad'
        }
    ]
    /** E data **/

    /** S computed **/

    /** E computed **/

    /** S methods **/

    async getPages() {
        if (this.id) {
            this.$store.dispatch('getPages', { id: this.id })
        }
    }
    /** E methods **/
    /** S life cycle **/
    created() {
        this.id = this.$route.query.id
        this.API_TYPE= this.$route.query.API_TYPE || '1'
        this.getPages()
        console.log(this.$route.query);
        
    }
    /** E life cycle **/
}
